(function () {
    'use strict';

    angular.module('aerosApp')
        .component('aflImportSor', {
            controller: aflImportSorController,
            templateUrl: '/static/javascript/directives/afl-import-sor/afl-import-sor.html',
            bindings: {
                projectId: '<'
            }
        });

    aflImportSorController.$inject = ['$q', 'Notification', 'aerosApi'];

    function aflImportSorController($q, Notification, aerosApi) {

        angular.extend(this, {
            uploadFile: uploadFile,
            selectFilesOfDirectory: selectFilesOfDirectory,
            uploadDirectory: uploadDirectory,
            uploadZipFile: uploadZipFile
        });

        //remove it after bound to real api
        (function initExampleData(scope) {
            scope.error = {
                message: "Some fields are not complete"
            };

            var missingFieldsCount = Math.floor(Math.random() * 10) + 1;
            scope.missingFields = [];

            for (var i = 0; i < missingFieldsCount; i++) {
                scope.missingFields.push({
                    param: 'field' + (i+1),
                    title: 'Field #' + (i+1)
                })
            }

        })(this);

        function uploadFile() {
            aerosApi.importSorFiles(this.projectId, {
                sorFile: this.file,
                isFODBlocksRequired: true,
                isUsedForMultipleImport: false,
                pathToTheDirectory: this.file.webkitRelativePath
            })
                .catch(fileUploadErrorHandler);
        }

        function selectFilesOfDirectory($files, $invalidFiles) {
            this.directory = $files;
        }

        function uploadDirectorySeparate() {
            var projectId = this.projectId;
            var requests = this.directory.map(function (file) {
                return aerosApi.importSorFiles(projectId, {
                    sorFile: file,
                    isFODBlocksRequired: true,
                    isUsedForMultipleImport: false,
                    pathToTheDirectory: file.webkitRelativePath
                });
            });

            $q.all(requests)
                .then(function(results){
                   Notification.success("Files have been sent successfully");
                })
                .catch(fileUploadErrorHandler);
        }

        function uploadDirectory() {

            var paths = this.directory.map(function (file) {
               return file.webkitRelativePath;
            });

            aerosApi.importSorFiles(this.projectId, {
                sorFile: this.directory,
                isFODBlocksRequired: true,
                isUsedForMultipleImport: true,
                pathToTheDirectory: paths
            })
                .catch(fileUploadErrorHandler);
        }

        function uploadZipFile() {
            aerosApi.importSorFiles(this.projectId, {
                sorFile: this.zipfile,
                isFODBlocksRequired: true,
                isUsedForMultipleImport: false,
                pathToTheDirectory: this.zipfile.webkitRelativePath
            })
                .catch(fileUploadErrorHandler);
        }

        function fileUploadErrorHandler(error) {
            Notification.error("Error during import", error);
        }

    }
})();
